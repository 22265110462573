import * as React from "react";

import {
  GridColDef,
  GridFilterModel,
  GridHeaderFilterCellProps
} from "@mui/x-data-grid-pro";

import EmployeeTableSelectFilter from "./filters";

import { EmployeeFilterActions, FilterListName } from "~/lib/employeesList";

export enum EmployeeTableHeaders {
  FirstName = "firstName",
  LastName = "lastName",
  Name = "name",
  ExternalId = "externalId",
  Status = "status",
  Division = "division",
  Manager = "manager",
  Groups = "groups"
}

type Filter = {
  dispatch: (value: EmployeeFilterActions) => void;
  options: { id: string; name: string }[];
  dispatchType: string;
  dispatchListName: string;
  initialState: GridFilterModel | undefined;
  noOptionText: string;
};

const gridHeaders = (
  headerName: string,
  props?: { filter?: Filter }
): GridColDef => {
  const basicHeaderProps: Omit<GridColDef, "type" | "field"> = {
    minWidth: 150,
    flex: 1
  };
  const headers: { [key: string]: GridColDef } = {
    firstName: {
      ...basicHeaderProps,
      field: "given_name",
      headerName: "First name",
      renderCell: params => {
        const name = params.value?.given_name;
        return name;
      }
    },
    lastName: {
      ...basicHeaderProps,
      field: "family_name",
      headerName: "Last name",
      renderCell: params => {
        const name = params.value?.family_name;
        return name;
      }
    },
    name: {
      ...basicHeaderProps,
      flex: 2,
      field: "name",
      headerName: "Employee",
      renderCell: params => {
        const name = params.value?.name;
        return name;
      }
    },
    externalId: {
      ...basicHeaderProps,
      field: "external_id",
      headerName: "External ID",
      renderCell: params => {
        const externalId = params.value;
        return externalId;
      }
    },
    status: {
      ...basicHeaderProps,
      field: "active_events",
      headerName: "Status",
      renderCell: params => {
        const activeEvents = params.value;
        if (activeEvents && activeEvents.length > 0) {
          const activeEventLabels = activeEvents
            .map((ae: any) => ae.label.name)
            .join(", ");
          return activeEventLabels;
        }
        return <></>;
      }
    },
    division: {
      ...basicHeaderProps,
      field: "division",
      headerName: "Division",
      renderCell: params => {
        const divisionName = params.value?.name;
        return divisionName;
      }
    },
    manager: {
      ...basicHeaderProps,
      flex: 2,
      field: "team_lead",
      headerName: "Manager",
      renderCell: params => {
        const teamLeadName = params.value?.name;
        return teamLeadName;
      }
    },
    groups: {
      ...basicHeaderProps,
      field: "groups",
      headerName: "Groups",
      renderCell: params => {
        const groups =
          params.value?.map((group: any) => group.name).join(", ") || "";
        return groups;
      }
    }
  };
  const filters: { [key: string]: any } = {
    manager: (params: GridHeaderFilterCellProps & Filter) => (
      <EmployeeTableSelectFilter
        {...params}
        noOptionText={props?.filter?.noOptionText}
        initialState={props?.filter?.initialState}
        onModelChange={(items, displayEmpty) => {
          props?.filter?.dispatch({
            type: props?.filter?.dispatchType as any,
            listName: props?.filter?.dispatchListName as FilterListName,
            items,
            displayEmpty
          });
        }}
        options={(props?.filter?.options || []).map(manager => ({
          value: manager.id,
          label: manager.name
        }))}
      />
    ),
    groups: (params: GridHeaderFilterCellProps & Filter) => (
      <EmployeeTableSelectFilter
        {...params}
        noOptionText={props?.filter?.noOptionText}
        initialState={props?.filter?.initialState}
        onModelChange={(items, displayEmpty) => {
          props?.filter?.dispatch({
            type: props?.filter?.dispatchType as any,
            listName: props?.filter?.dispatchListName as FilterListName,
            items,
            displayEmpty
          });
        }}
        options={(props?.filter?.options || []).map(groups => ({
          value: groups.id,
          label: groups.name
        }))}
      />
    )
  };

  const header = headers[headerName];
  if (!header) {
    return {
      field: headerName,
      headerName,
      renderCell: params => {
        const { value } = params;
        return value;
      }
    };
  }
  if (props?.filter) {
    header.renderHeaderFilter = props => filters[headerName](props);
  }
  return headers[headerName];
};

export default gridHeaders;
