import * as React from "react";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  GridColDef,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
  MuiEvent,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

import DataGrid from "./DataGrid";
import gridHeaders, { EmployeeTableHeaders } from "./GridHeaders";

import * as api from "~/api";
import {
  EmployeeDisplayList,
  EmployeeFilterActions
} from "~/lib/employeesList";
// import { FilterListItem } from "~/lib/filterList";

const STORAGE_KEY = "dataGridState";

type EmployeesTableProps = {
  deactivatedMode: boolean;
  displayList: EmployeeDisplayList;
  dispatch: (value: EmployeeFilterActions) => void;
  groups: api.Group[];
  managers: api.IdentificationEmployee[];
  // statuses: FilterListItem[];
  // onRequestAllEmployeeIds?: () => any;
  onSelectEmployees?: (selectedEmployeeIds: api.Employee["id"][]) => any;
  // onEditStatusClick?: (employee: api.Employee, labelKey: api.AnyStatus) => any;
  // onLoadMoreEmployees: () => any;
  // onReactivateClick?: (employee: api.Employee) => any;
  onPageChange?: (page: number, pageSize: number) => void;
  onSortChange?: (order: string) => void;
  isLoading?: boolean;
  initPage?: number;
  initPageSize?: number;
  storageType?: "LOCAL" | "SESSION";
};

export const EmployeesTable: any = ({
  displayList,
  deactivatedMode,
  managers,
  groups,
  isLoading,
  onSelectEmployees,
  onPageChange,
  onSortChange,
  dispatch,
  initPage = 0,
  initPageSize = 100,
  storageType = "SESSION"
}: EmployeesTableProps) => {
  const apiRef = useGridApiRef();
  const [paginationModel, setPaginationModel] = useState({
    page: initPage,
    pageSize: initPageSize
  });
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [initialState, setInitialState] = useState<GridInitialState>();

  const storage = { LOCAL: localStorage, SESSION: sessionStorage }[storageType];
  const history = useHistory();

  useEffect(() => {
    if (!isLoading) {
      apiRef.current.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true
      });
    }
  }, [apiRef, isLoading]);

  const saveSnapshot = useCallback(() => {
    if (apiRef?.current?.exportState && storage) {
      const currentState: GridInitialStatePro = apiRef.current.exportState();
      storage.setItem(STORAGE_KEY, JSON.stringify(currentState));
    }
  }, [apiRef, storage]);

  useLayoutEffect(() => {
    let newState: GridInitialStatePro | undefined;
    const storagedData = storage?.getItem(STORAGE_KEY);
    if (storagedData) {
      try {
        newState = JSON.parse(storagedData);
      } catch (e) {
        console.error(e);
      }
    }
    setInitialState(newState || {});
    if (newState?.filter?.filterModel) {
      setFilterModel(newState.filter.filterModel);
    }
    // TODO: (Beyond parity) Save sorting isn't currently supoprted by prev table
    // if (newState?.sorting?.sortModel) {
    //   setSortModel(newState.sorting.sortModel);
    // }
    // TODO: (Beyond parity) Save page isn't currently supoprted by prev table
    // if (newState?.pagination) {
    //   setPaginationModel(newState.pagination);
    // }
    return () => saveSnapshot();
  }, [saveSnapshot, storage]);

  const columns: GridColDef[] = [
    gridHeaders(EmployeeTableHeaders.FirstName),
    gridHeaders(EmployeeTableHeaders.LastName),
    gridHeaders(EmployeeTableHeaders.ExternalId),
    gridHeaders(EmployeeTableHeaders.Status),
    gridHeaders(EmployeeTableHeaders.Division),
    gridHeaders(EmployeeTableHeaders.Manager, {
      filter: {
        dispatch,
        options: managers,
        dispatchType: "updateVisibleByFilterItems",
        dispatchListName: "activeManagers",
        initialState: initialState?.filter?.filterModel,
        noOptionText: "(No managers)"
      }
    }),
    gridHeaders(EmployeeTableHeaders.Groups, {
      filter: {
        dispatch,
        options: groups,
        dispatchType: "updateVisibleByFilterItems",
        dispatchListName: "activeGroups",
        initialState: initialState?.filter?.filterModel,
        noOptionText: "(No groups)"
      }
    })
  ];

  const onPaginationChange = useCallback(
    (model: GridPaginationModel): void => {
      const newPaginationModel: GridPaginationModel = {
        ...paginationModel,
        ...model
      };
      setPaginationModel(newPaginationModel);
      onPageChange?.(newPaginationModel.page, newPaginationModel.pageSize);
    },
    [onPageChange, paginationModel]
  );

  const onSortModelChange = useCallback(
    (sortModel: GridSortModel): void => {
      const newSortModel = { sortModel: [...sortModel] };
      const sortingString = newSortModel.sortModel
        .map((prop: any) => {
          return `${prop.sort === "asc" ? "" : "-"}${prop.field}`;
        })
        .join(",")
        .replaceAll("team_lead", "team_lead__name")
        .replaceAll("division", "division__name");
      setSortModel(sortModel);
      onSortChange?.(sortingString);
      saveSnapshot();
    },
    [onSortChange, saveSnapshot]
  );

  const onFilterModelChange = (newFilterModel: GridFilterModel): void => {
    const newItems: GridFilterModel["items"] = [...(filterModel?.items || [])];
    newFilterModel.items?.forEach(item => {
      const index = newItems.findIndex(newItem => newItem.field === item.field);
      if (index === -1) {
        newItems.push(item);
      } else {
        newItems[index] = item;
      }
    });
    setFilterModel({ ...filterModel, items: newItems });
    saveSnapshot();
  };

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel
  ): void => {
    onSelectEmployees?.(newRowSelectionModel as string[]);
  };

  const onRowClick = (param: GridRowParams, event: MuiEvent): void => {
    const { id } = param.row;
    if (!deactivatedMode) {
      history.push(`/dashboard/new-employees/employee/${id}`);
    }
  };

  return (
    <DataGrid
      // API
      apiRef={apiRef}
      // Data
      rows={displayList.employees}
      columns={columns}
      rowCount={displayList.count || 0}
      // Sorting
      sortModel={sortModel}
      sortingMode="server"
      onSortModelChange={onSortModelChange}
      // Filtering
      filterMode="server"
      filterModel={filterModel}
      onFilterModelChange={onFilterModelChange}
      headerFilters
      // Pagination
      pagination
      paginationMode="server"
      pageSizeOptions={[10, 25, 50, 100, 500, 1000]}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationChange}
      // Clicking
      getRowClassName={() => "cursor-pointer"}
      onRowClick={onRowClick}
      disableRowSelectionOnClick
      // Selection
      rowSelection
      checkboxSelection
      // TODO: Should general select, select all database rows
      onRowSelectionModelChange={onRowSelectionModelChange}
      // Status
      loading={isLoading || initialState === undefined}
    />
  );
};
