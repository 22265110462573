import * as React from "react";

import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";

import ThemeLayout from "../Theme/ThemeLayout";

const DataGrid = (
  props: DataGridProProps
): React.ReactElement<DataGridProProps> => {
  // Note: To fix stylying issues if moved to createTheme: https://github.com/mui/mui-x/issues/2754
  const styles = {
    "& .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  };

  return (
    <ThemeLayout>
      <DataGridPro {...props} sx={styles}></DataGridPro>
    </ThemeLayout>
  );
};

export default DataGrid;
