import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  gridFilterModelSelector,
  useGridSelector,
  useGridApiContext,
  GridHeaderFilterCellProps,
  GridFilterItem,
  GridFilterModel
} from "@mui/x-data-grid-pro";

type EmployeeTableSelectFilterProps = GridHeaderFilterCellProps & {
  onModelChange: (items: any, displayEmpty: boolean) => void;
  optionKey?: string;
  options: { label: string; value: string }[];
  initialState?: GridFilterModel;
  noOptionText?: string;
};

enum FilterOptions {
  None = "none",
  All = "all",
  Unselect = "unselect"
}

const EmployeeTableSelectFilter = ({
  colDef,
  onModelChange,
  options,
  noOptionText,
  initialState,
  optionKey = "id"
}: EmployeeTableSelectFilterProps): JSX.Element => {
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = React.useMemo(
    () => filterModel.items?.filter(({ field }) => field === colDef.field),
    [colDef.field, filterModel.items]
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [initItems, setInitItems] = useState<string[]>([]);

  const getCurrentFilter = useCallback((): GridFilterItem | undefined => {
    return currentFieldFilters.find(({ field }) => field === colDef.field);
  }, [colDef.field, currentFieldFilters]);

  const getCurrentItem = useCallback((): GridFilterItem | undefined => {
    return filterModel.items.find(({ field }) => field === colDef.field);
  }, [colDef.field, filterModel.items]);

  useEffect(() => {
    setInitItems(
      initialState?.items.find(({ field }) => field === colDef.field)?.value
    );
  }, [colDef.field, initialState]);

  useEffect(() => {
    const filterItems = getCurrentItem();
    if (filterItems && isUpdating) {
      setIsUpdating(false);
      const items = {
        ...filterItems,
        value: filterItems.value.filter((v: string) => v !== FilterOptions.All)
      };
      onModelChange(items.value, items.value.includes(FilterOptions.None));
    }
  }, [
    colDef.field,
    filterModel,
    getCurrentItem,
    isUpdating,
    onModelChange,
    optionKey
  ]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      setIsUpdating(true);
      const { value } = event.target;
      const filter = getCurrentFilter();
      let newValues: string[] = [...value];

      const areAllOptionsSelected = options.every(o => value.includes(o.value));
      const isNoneSelected = value.includes(FilterOptions.None);
      const lastClicked = newValues.find(v => !filter?.value?.includes(v));

      const addValuesToFilter = (values: string | string[]): void => {
        const defaultFilter = { field: colDef.field, operator: "is" };
        apiRef.current.upsertFilterItem({
          ...(filter || defaultFilter),
          value: Array.isArray(values) ? values : [values]
        });
      };

      if (lastClicked === FilterOptions.Unselect) {
        newValues = [];
        setIsAllSelected(false);
        addValuesToFilter(newValues);
        return;
      }
      if (
        lastClicked === FilterOptions.All ||
        (areAllOptionsSelected && isNoneSelected)
      ) {
        newValues = [
          ...options.map(o => o.value),
          FilterOptions.All,
          FilterOptions.None
        ];
        setIsAllSelected(true);
      } else {
        newValues = newValues.filter(v => v !== FilterOptions.All);
        setIsAllSelected(false);
      }
      addValuesToFilter(newValues);
    },
    [apiRef, colDef.field, getCurrentFilter, options]
  );

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id={`select-mui-${colDef.field}-label`}>Filter</InputLabel>
      <Select
        labelId={`select-mui-${colDef.field}-label`}
        id={`select-mui-${colDef.field}`}
        value={getCurrentFilter()?.value || initItems || []}
        onChange={handleChange}
        multiple
        label="Filter"
      >
        <MenuItem
          value={isAllSelected ? FilterOptions.Unselect : FilterOptions.All}
          divider
        >
          {isAllSelected ? "Unselect All" : "Select All"}
        </MenuItem>
        {options
          .sort((a, b) => a.label.localeCompare(b.label))
          .map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        <MenuItem value={FilterOptions.None}>
          {noOptionText ? noOptionText : `No ${colDef.field}`}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default EmployeeTableSelectFilter;
